import React, { useState, useEffect } from 'react';
import Axios from '../../config/axios';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { toast, confirm } from '@rickylandino/react-messages';
import { Fragment } from 'react';
import Card from 'react-bootstrap/Card';
import statesList from '../Helpers/StatesList';
import NumberFormat from 'react-number-format';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Moment from 'moment';
import { dateWithNoTimezone } from '../Helpers/DateFormat';
import { useForm, Controller } from "react-hook-form";
import Globals from '../../config/globals';
import { Modal, message } from 'antd';
import CVRequestManageHoursSlider from './CVRequestManageHoursSlider'
import CVSendAwardNotificationSlider from './CVSendAwardNotificationSlider';

/*import { useForm, Controller } from "react-hook-form";*/

import { Select } from 'antd';

export default function CVRequestDetailsSlider(props) {
    const { register, getValues, setValue, control, watch } = useForm();

    const { Option } = Select;

    const [state, setState] = useState({
        showPane: props.showPane,
        selectedRequestId: '',
        selectedIdx: '',
        formFields: {},
        local777UseGrantedOrDenied: '',
        isHoursGrantedDisabled: false,
        requests: [],
        showManageHoursSlider: false,
        showSendAwardNotificationSlider: false
    });

    var userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    //useEffect(() => {
    //    const subscription = watch((value, { name, type }) => {
    //        //console.log(value);
    //        //console.log(name);
    //        //console.log(type);
    //    });

    //    return () => subscription.unsubscribe();

    //}, []);

    useEffect(() => {
        if (props.showPane) {
            if (props.selectedRequestId !== null && props.selectedRequestId !== "") {
                Axios.get(`/api/GetRequestById`, {
                    params: {
                        requestID: props.selectedRequestId
                    }
                }).then(response => {
                    setValue("formFields", response.data.request);
                    setValue("formFields.agentsList", response.data.agents);

                    setValue('jobs_ID', response.data.request.mrRequest.jobs_ID);
                    setValue('jobName', response.data.request.jobName);

                    if (getValues().formFields.mrRequest.expectedStartDate === '0001-01-01T00:00:00' || getValues().formFields.mrRequest.expectedStartDate === null) {
                        setValue('formFields.mrRequest.expectedStartDate', 'N/A');
                    }
                    else {
                        setValue('formFields.mrRequest.expectedStartDate', Moment(dateWithNoTimezone(getValues().formFields?.mrRequest.expectedStartDate)).format("L"));
                    }

                    if (getValues().formFields.mrRequest.anticipatedCompletionDate === '0001-01-01T00:00:00' || getValues().formFields.mrRequest.anticipatedCompletionDate === null) {
                        setValue('formFields.mrRequest.anticipatedCompletionDate', 'N/A');
                    }
                    else {
                        setValue('formFields.mrRequest.anticipatedCompletionDate', Moment(dateWithNoTimezone(getValues().formFields?.mrRequest.anticipatedCompletionDate)).format("L"));
                    }

                    setState({
                        ...state,
                        formFields: response.data,
                        showPane: props.showPane,
                        requests: props.requests,
                        selectedRequestId: props.selectedRequestId,
                        selectedIdx: props.selectedIdx,
                        requests: props.requests,
                        showManageHoursSlider: false,
                        showSendAwardNotificationSlider: false
                    });

                }).catch(error => {
                    console.log(error);
                });
            }
        }
    }, [props]);

    function hidePane() {
        //now update the parent list
        //don't need to do anything since the parent updateTableDisplay goes back and loads all Requests from the DB

        //let requests = props.requests;
        //const selectedIdx = requests.findIndex(r => r.mrRequest.requestID === getValues().formFields.mrRequest.requestID);
        //requests[selectedIdx] = getValues().formFields;

        //for graceful, animated close
        setTimeout(() => props.updateTableDisplay(), 1000);

        setState({
            ...state,
            showPane: false,
            showManageHoursSlider: false,
            showSendAwardNotificationSlider: false
        });
    }

    async function updateRequestStatus() {
        setValue('formFields.mrRequest.requestStatus', 'Awarded');

        setState({
            ...state,
            showManageHoursSlider: false,
            showSendAwardNotificationSlider: false
        });
    }

    //function onPrintRequestPDFClick() {

    //    let postdata = {
    //        RequestID: getValues().formFields.mrRequest.requestID
    //    }

    //    Axios.post(`/api/GetRequestPDF`, postdata, {
    //        responseType: 'blob'
    //    }).then(response => {
    //        //console.log(response);

    //        //open in new browser tab
    //        const file = new Blob(
    //            [response.data],
    //            { type: 'application/pdf' });

    //        const fileURL = URL.createObjectURL(file);

    //        var newWindow = window.open(fileURL);

    //        setTimeout(function () {
    //            newWindow.document.title = "Market Recovery Request";
    //        }, 1000);

    //    }).catch(error => {
    //        console.log(error);
    //    });
    //}

    function onPrintRequestPDFClick() {
        let myFileName = "MRRequest_" + state.selectedRequestId;

        const key = "loading";
        message.loading({
            content: 'PDF generation in progress...',
            key,
            duration: 0,
            style: {
                position: "fixed",
                bottom: 0,
                left: 0
            },
        });

        let postdata = {
            RequestID: getValues().formFields.mrRequest.requestID
        }

        Axios.post(`/api/GetRequestPDF`, postdata, {
            responseType: 'blob'
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = myFileName + ".pdf";
            a.click();
            window.URL.revokeObjectURL(url);

            message.destroy(key);
        }).catch(error => {
            console.log(error);
        });
    }

    function onManageHoursClick() {
        setState({
            ...state,
            showManageHoursSlider: true
        });
    }

    function onSendAwardNotificationClick() {
        setState({
            ...state,
            showSendAwardNotificationSlider: true
        });
    }

    function onSetBillingStatus(event) {
        let billingstatus = event.target.getAttribute('newstatus');

        let postdata = {
            RequestID: getValues().formFields.mrRequest.requestID,
            BillingStatus: billingstatus
        }

        Axios.post(`/api/UpdateBillingStatus`, postdata
        ).then(response => {
            setValue('formFields.mrRequest.billingStatus', billingstatus)

            setState({
                ...state
            });

        }).catch(error => {
            console.log(error);
        });
    }

    return (
        <>
            <SlidingPane
                className='some-custom-class w-65'
                overlayClassName='showCard'
                isOpen={state.showPane}
                title='Market Recovery Request Info'
                onRequestClose={hidePane}
                width={'85%'}
            >
                <div className="slide-pane-body scrollableDiv">
                    <div>
                        {!state.loading &&
                            <Fragment>
                                {/*<div className="panel-hdr">*/}
                                {/*    {state.selectedRequestId && (<h2>{state.formFields.contactName}</h2>)}*/}
                                {/*    {!state.selectedRequestId && (<h2>New Market Recovery Request</h2>)}*/}
                                {/*</div>*/}
                                <Card className="local777Card">
                                    <Card.Header><span>Process Request for Market Recovery</span></Card.Header>
                                    <Card.Body>
                                        <div className="row">
                                            <div className="col-lg-2">
                                                <label className="form-label mb-0">Request Status:</label>
                                            </div>
                                            <div className="col-lg-10" style={{ color: "red" }}>
                                                <span>{getValues().formFields?.mrRequest.requestStatus}</span>
                                            </div>

                                            <div className="col-lg-2">
                                                <label className="form-label mb-0">Contractor:</label>
                                            </div>
                                            <div className="col-lg-10">
                                                <span>{getValues().formFields?.contractorName}</span>
                                            </div>

                                            <div className="col-lg-2">
                                                <label className="form-label mb-0">Contact Name:</label>
                                            </div>
                                            <div className="col-lg-4">
                                                <span>{getValues().formFields?.contactName}</span>
                                            </div>

                                            <div className="col-lg-2">
                                                <label className="form-label mb-0">Contact Title:</label>
                                            </div>
                                            <div className="col-lg-4">
                                                <span>{getValues().formFields?.contactTitle}</span>
                                            </div>

                                            <div className="col-lg-2">
                                                <label className="form-label mb-0">Contact Email:</label>
                                            </div>
                                            <div className="col-lg-10">
                                                <span>{getValues().formFields?.contactEmail}</span>
                                            </div>

                                            <div className="col-lg-2">
                                                <label className="form-label mb-0">Phone #:</label>
                                            </div>
                                            <div className="col-lg-4">
                                                <span>{getValues().formFields?.contractorPhone}</span>
                                            </div>

                                            <div className="col-lg-2">
                                                <label className="form-label mb-0">Fax #:</label>
                                            </div>
                                            <div className="col-lg-4">
                                                <span>{getValues().formFields?.contractorFax}</span>
                                            </div>

                                            <div className="col-lg-2">
                                                <label className="form-label mb-0">Bid Date:</label>
                                            </div>
                                            <div className="col-lg-10">
                                                <span>{getValues().formFields?.mrRequest.bidDate && Moment(dateWithNoTimezone(getValues().formFields?.mrRequest?.bidDate)).format("L")}</span>
                                            </div>

                                            <div className="col-lg-2">
                                                <label className="form-label mb-0">Prevailing Wage Job:</label>
                                            </div>
                                            <div className="col-lg-10">
                                                <span>{getValues().formFields?.mrRequest.prevailingWageJob === 1 ? 'Yes' : 'No'}</span>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>

                                <Card className="local777Card">
                                    <Card.Header><span>Job Information</span></Card.Header>
                                    <Card.Body>
                                        <div className="row">
                                            <div className="col-lg-3">
                                                <label className="form-label mb-0">Project Name:</label>
                                            </div>
                                            <div className="col-lg-9">
                                                <span>{getValues().formFields?.mrRequest.projectName}</span>
                                            </div>

                                            <div className="col-lg-3">
                                                <label className="form-label mb-0">Building/Suite/Floor:</label>
                                            </div>
                                            <div className="col-lg-9">
                                                <span>{getValues().formFields?.mrRequest.projectDetails}</span>
                                            </div>

                                            <div className="col-lg-3">
                                                <label className="form-label mb-0">Job Site Address:</label>
                                            </div>
                                            <div className="col-lg-9">
                                                <span>{getValues().formFields?.mrRequest.jobsiteAddress}</span>
                                            </div>

                                            <div className="col-lg-3">
                                                <label className="form-label mb-0">City, State & Zip:</label>
                                            </div>
                                            <div className="col-lg-9">
                                                <span>{getValues().formFields?.mrRequest.jobsiteCityState}</span>
                                            </div>

                                            <div className="col-lg-3">
                                                <label className="form-label mb-0">Type of Hours:</label>
                                            </div>
                                            <div className="col-lg-9">
                                                <span>{getValues().formFields?.mrRequest.typeOfHours}</span>
                                            </div>


                                            <div className="col-lg-3">
                                                <label className="form-label mb-0">Work Description:</label>
                                            </div>
                                            <div className="col-lg-9">
                                                <span>{getValues().formFields?.mrRequest.workDescription}</span>
                                            </div>

                                            <div className="col-lg-3">
                                                <label className="form-label mb-0">Concessions Made:</label>
                                            </div>
                                            <div className="col-lg-9">
                                                <span>{getValues().formFields?.mrRequest.concessionsMade}</span>
                                            </div>

                                            <div className="col-lg-3">
                                                <label className="form-label mb-0">Hours Summary:</label>
                                            </div>
                                            <div className="col-lg-9">
                                                <span>Requested: <strong>{getValues().formFields?.mrRequest.requestedHours}</strong>, Granted: <strong>{getValues().formFields?.mrRequest.grantedHours}</strong>, Hours Paid Manually: <strong>{getValues().formFields?.mrRequest.hoursPaidManually}</strong></span>
                                            </div>

                                            <div className="col-lg-3">
                                                <label className="form-label mb-0">Awarding Party:</label>
                                            </div>
                                            <div className="col-lg-9">
                                                <span>{getValues().formFields?.mrRequest.awardingParty}. Contact: {getValues().formFields?.mrRequest.jobsiteContactPerson}, {getValues().formFields?.mrRequest.jobsiteContactPhone}</span>
                                            </div>

                                            <div className="col-lg-3">
                                                <label className="form-label mb-0">Expecting Start Date:</label>
                                            </div>
                                            <div className="col-lg-9">
                                                {getValues().formFields?.mrRequest.expectedStartDate}
                                            </div>

                                            <div className="col-lg-3">
                                                <label className="form-label mb-0">Anticipated Completion Date:</label>
                                            </div>
                                            <div className="col-lg-9">
                                                {getValues().formFields?.mrRequest.anticipatedCompletionDate}
                                            </div>

                                        </div>
                                    </Card.Body>
                                </Card>
                                <div className="row">
                                    <div className="col col-lg-6 col-12">
                                        <Card className="local777Card">
                                            <Card.Header><span>Union Bidders</span></Card.Header>
                                            <Card.Body>
                                                <div className="row">
                                                    <div className="col-12">
                                                        {getValues().formFields?.mrRequest.unionBidder1}
                                                    </div>
                                                    <div className="col-12">
                                                        {getValues().formFields?.mrRequest.unionBidder2}
                                                    </div>
                                                    <div className="col-12">
                                                        {getValues().formFields?.mrRequest.unionBidder3}
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className="col col-lg-6 col-12">
                                        <Card className="local777Card">
                                            <Card.Header><span>Non Union Bidders</span></Card.Header>
                                            <Card.Body>
                                                <div className="row">
                                                    <div className="col-12">
                                                        {getValues().formFields?.mrRequest.nonUnionBidder1}
                                                    </div>
                                                    <div className="col-12">
                                                        {getValues().formFields?.mrRequest.nonUnionBidder2}
                                                    </div>
                                                    <div className="col-12">
                                                        {getValues().formFields?.mrRequest.nonUnionBidder3}
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col col-12">
                                        <p>In order to expedite payment requests, please click the link below to notify us when you have submitted your final hours for this Market Recovery Request</p>
                                        {/*<p>*/}
                                        {/*    If approved at $10/hour or more it is on a one to one basis. For every person from the company working on the job site with the tools, there will be an*/}
                                        {/*    equal amount of personnel sent from the Union Hall's "Out of Work List". Any violation of this agreement will cancel further payments for Market Recovery*/}
                                        {/*    for the job. If you have any questions, please call.*/}
                                        {/*</p>*/}
                                    </div>
                                </div>

                                {state.showManageHoursSlider &&
                                    <CVRequestManageHoursSlider showPane={state.showManageHoursSlider} request={getValues().formFields} selectedRequestId={state.selectedRequestId} hidePane={() => setState({ ...state, showManageHoursSlider: false })} />
                                }
                                {state.showSendAwardNotificationSlider &&
                                    <CVSendAwardNotificationSlider showPane={state.showSendAwardNotificationSlider} updateRequestStatus={updateRequestStatus} request={getValues().formFields} selectedRequestId={state.selectedRequestId} hidePane={() => setState({ ...state, showSendAwardNotificationSlider: false })} />
                                }
                            </Fragment>
                        }
                    </div>
                </div>

                <div className="modal-footer">
                    <div className="form-group col-12 padding-25-10">
                        <span style={getValues().formFields?.mrRequest.requestStatus === 'Awarded' && getValues().formFields?.mrRequest.awardNotificationSent !== null ? {} : { display: 'none' }}><button className="btn btn-submit btn-sm" onClick={onManageHoursClick} disabled={userInfo.agreedtoterms === null ? true : false}>Manage Hours</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <span><button className="btn btn-submit btn-sm" onClick={onPrintRequestPDFClick}>Print PDF</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <span style={getValues().formFields?.mrRequest.requestStatus === 'Granted' ? {} : { display: 'none' }}><button className="btn btn-submit btn-sm" onClick={onSendAwardNotificationClick}>Send Award Notification</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <span style={getValues().formFields?.mrRequest.requestStatus === 'Awarded' ? {} : { display: 'none' }}>
                            <label className="form-label ms-8">Billing Status:</label> <span className="color-dark-blue fw-bold">{getValues().formFields?.mrRequest.billingStatus === null || getValues().formFields?.mrRequest.billingStatus === 'Open' ? 'Open' : 'Finalized'}</span>
                            <span style={getValues().formFields?.mrRequest.billingStatus === null || getValues().formFields?.mrRequest.billingStatus === 'Open' ? {} : { display: 'none' }}><button className="btn btn-submit btn-sm ms-3" newstatus='Final' onClick={onSetBillingStatus} disabled={userInfo.agreedtoterms === null ? true : false}>Final Bill Submitted</button></span>
                            <span style={getValues().formFields?.mrRequest.billingStatus === 'Final' ? {} : { display: 'none' }}><button className="btn btn-submit btn-sm ms-3" newstatus='Open' onClick={onSetBillingStatus}>Re-Open Billing</button></span>
                        </span>
                        <button className="btn btn-outline-primary margin-left-15 float-right" onClick={hidePane}>Close</button>
                    </div>
                </div>
            </SlidingPane>
        </>
    );
}