import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { message } from 'antd';
import Axios from '../../config/axios';
import { toast, confirm } from '@rickylandino/react-messages';
import ReactLoading from 'react-loading';
import logo from '../../img/local21_logo_sm.png';

export default function ResetPassword(props) {
    const navigate = useNavigate();
    const location = useLocation();

    const [state, setState] = useState({
        linkExpired: false
    });

    useEffect(() => {
        const myParams = location.pathname.split("/");
        console.log(myParams);

        let postdata = {
            pwdate: myParams[3]
        }

        Axios.post(`/api/DecodeDate`, postdata
        ).then(response => {
            if (response.data) {
                setState({
                    ...state,
                    linkExpired: true
                });
            }
        }).catch(error => {
            console.log(error);
        });
    }, []);

    function setLinkExpired() {
        setState({
            ...state,
            linkExpired: true
        });
    }

    function requestLink() {
        const myParams = location.pathname.split("/");

        const key = "sendresetpasswordemail";
        message.loading({
            content: 'Sending Email With Link To Reset Your Password...',
            key,
            duration: 0
        });

        var email = "";
        let postdata = {};
        postdata.email = myParams[2];
        Axios.post(`/api/ResendPasswordResetEmail`, postdata
        ).then(response => {
            message.destroy(key);

            if (response.data === true) {
                toast.success("An email to reset your password has been sent.");
            }
            else {
                let message = "Something went wrong, please try again later or call Marie E. Bajardi  at (914) 737-2166 or <a href=\"mailto:marie@local21union.com\">Marie@local21union.com</a>.";
                toast.error(<span style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: message }}></span>, { alignment: 'top-center', duration: 5 });
            }

            props.history.push({
                pathname: '/'
            });
        }).catch(error => {
            console.log(error);
        });
        
        
    }

    function sendEmail(username) {

        var date = new Date();
        var secret = 'mysecret';

        let postdata = {};
        postdata.email = username;
        Axios.post(`/api/SendUserEmail`, postdata
        ).then(response => {
        }).catch(error => {
            console.log(error);
        });
    }

    function handleSubmit(e) {
        e.preventDefault(); 

        setState({
            ...state,
            loading: true
        });

        var newpass = document.getElementById("newpass");
        var newpass2 = document.getElementById("newpass2");

        if (newpass.value === '') {
            setState({
                ...state,
                loading: false
            });
            toast.error("Passwords cannot be blank. Please try again.");
        }
        else if (newpass.value !== newpass2.value) {
            setState({
                ...state,
                loading: false
            });
            toast.error("Passwords do not match. Please try again.");
        }
        else if (newpass.value.length < 8) {
            setState({
                ...state,
                loading: false
            });
            toast.error("Passwords must be a minumum of 8 characters. Please try again.");
        }
        else if (newpass.value.length > 15) {
            setState({
                ...state,
                loading: false
            });
            toast.error("Passwords must be a maximum of 15 characters. Please try again.");
        }
        else {
            //Password must be: 8 to 15 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character !@#$%^&*
            if (checkPassword(newpass.value) === false) {
                setState({
                    ...state,
                    loading: false
                });
                toast.error("Password does not meet criteria requirements. Please try again.");
                return;
            }

            const myParams = location.pathname.split("/");

            let postdata = {};
            postdata.password = newpass.value;
            postdata.email = myParams[2];

            Axios.post(`/api/ResetUserPassword`, postdata
            ).then(response => {

                console.log(response.data);

                setState({
                    ...state,
                    loading: false
                });

                if (response.data === true) {
                    toast.success("Your password has been reset.");
                }
                else {
                    let message = "Something went wrong, please try again later or call Marie E. Bajardi  at (914) 737-2166 or <a href=\"mailto:marie@local21union.com\">Marie@local21union.com</a>.";
                    toast.error(<span style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: message }}></span>, { alignment: 'top-center', duration: 5 });
                }
            }).catch(error => {
                setState({
                    ...state,
                    loading: false
                });

                let message = "Something went wrong, please try again later or call Marie E. Bajardi  at (914) 737-2166 or <a href=\"mailto:marie@local21union.com\">Marie@local21union.com</a>.";
                toast.error(<span style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: message }}></span>, { alignment: 'top-center', duration: 5 });
            });

            navigate("/", { replace: true });
        }
    }

    function checkPassword(str) {
        var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,15}$/;
        return re.test(str);
    }

    function goHome() {
        navigate("/", { replace: true });
    }

    const expiredLink = state.linkExpired;
    return (
            
        <div className="login-container">
            <div className="background">
                <div className="blankpage-form-field">
                    <div className="page-logo m-0 w-100 align-items-center justify-content-center rounded border-bottom-left-radius-0 border-bottom-right-radius-0 px-4">
                        <img src={logo} height="90px" alt="Local 21 Market Recovery" aria-roledescription="logo" />
                    </div>
                    <div className="card p-4 border-top-left-radius-0 border-top-right-radius-0">
                        {expiredLink ? (
                            <div>This link has expired. Please&nbsp;
                                <span onClick={requestLink} className="local777TextA">click here</span> to request a new password reset link.</div>
                        ) : (
                            <div>
                                <form>
                                    <h3>Password Reset</h3>
                                        <p>Your new password must meet the following criteria:
                                            <ul>
                                                <li>between 8 and 15 characters</li>
                                                <li>contain at least one lowercase letter</li>
                                                <li>one uppercase letter</li>
                                                <li>one numeric digit</li>
                                                <li>and one of these special characters: !@#$%^&*</li>
                                            </ul>
                                        </p>
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="password">New Password</label>
                                        <input id="newpass" type="password" className="form-control mb-4" />

                                    </div>
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="password">Confirm New Password</label>
                                        <input id="newpass2" type="password" className="form-control mb-4" />

                                    </div>
                                    {state.loading &&
                                        <ReactLoading className='tableLoading float-left' type={"spokes"} color={'#275282'} height={30} width={30} />
                                    }
                                    <button className="btn btn-default float-right" onClick={handleSubmit}>Submit</button>
                                </form>

                                <div className="blankpage-footer text-center row">
                                    <div className="local777TextA col-6 align-items-right a" onClick={goHome}><strong>Go To Login</strong></div>
                                    &nbsp;&nbsp;&nbsp;
                                </div>
                            </div>
                            )}
                    </div> 
                </div>
            </div>       
        </div>
    );
}